import React from 'react';
import sales_vemas from './images/sales_vemas.png'
import customer_vemas  from './images/customer_vemas.PNG'
import customerAdd_vemas  from './images/customerAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Customer = () => (
    <div className='content'>
        <h2>Customer Data Management</h2>
        <div className='img-text'>
            To user the system effectively and serve your customer better it is very important to capture customer information correctly. Customer information is used everywhere in the system for different business processors.
        </div>
        <h3>Add a new customer</h3>
        <div className='img-text'>
            Click on “Sales” from main menu on left hand side.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={sales_vemas}/>
        </div>
        <div className='img-text'>Once you clicked “Sales” menu, you will be taken to a page which list existing customers.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={customer_vemas}/>
        </div>
        <div className='img-text'>Click on “Add New” button to start the creation of a new customer.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={customerAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new customer.</div>
        <div className='img-text'>
            To edit customer details, just click on the button with pencil icon. 
            That will take you to the customer detail screen. 
            To edit any information simply click on the text box you wish to make changes and make the necessary changes. 
            Press save button to save changes.To delete customer, press the button bin icon. This will prompt a message to get your confirmation. You cannot recover once you deleted the customer.
        </div>
    </div>
);

export default Customer;