import React from 'react';
import menu_part_vemas from './images/menu_part_vemas.png'
import part_vemas  from './images/part_vemas.PNG'
import partAdd_vemas  from './images/partAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Part = () => (
    <div className='content'>
        <h2>Master Data Management</h2>
        <h3>Part</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={menu_part_vemas}/>
        </div>
        <div className='img-text'>Click on the gear icon and click on “Part”. This will take you to the part list screen which shows the available parts in the system.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={part_vemas}/>
        </div>
        <div className='img-text'>If you want to add a new part, just click on “Add New”</div>
        <div className='img-content'>
            <img alt="img" id="img" src={partAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new part.</div>
        <div className='img-text'>
            You can go to edit screen just by clicking the pencil icon. Part necessary changes and press “Save” button to save.You can delete parts just by clicking the bin icon 
        </div>
    </div>
);

export default Part;