import React from 'react';
import menu_vemas from './images/menu_serviceshe_vemas.png'
import serviceSchedule_vemas  from './images/serviceSchedule_vemas.PNG'
import serviceScheduleAdd_vemas  from './images/serviceScheduleAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const ServiceSchedule = () => (
    <div className='content'>
        <h2>Master Data Management</h2>
        <h3>Service Schedule</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={menu_vemas}/>
        </div>
        <div className='img-text'>Click on the gear icon and click on “Service Schedule”. This will take you to the service schedule list screen which shows the available service schedule in the system.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={serviceSchedule_vemas}/>
        </div>
        <div className='img-text'>If you want to add a new service schedule, just click on “Add New”</div>
        <div className='img-content'>
            <img alt="img" id="img" src={serviceScheduleAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new service schedule.</div>
        <div className='img-text'>
            You can go to edit screen just by clicking the pencil icon. Service schedule necessary changes and press “Save” button to save.You can delete service schedule just by clicking the bin icon 
        </div>
    </div>
);

export default ServiceSchedule;