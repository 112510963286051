import React from 'react';
import vehicle_vemas from './images/vehicle_vemas.png'
import jobCardHeader_vemas  from './images/jobCardHeader_vemas.PNG'
import jobCard_vemas  from './images/jobCard_vemas.PNG'
import jobCardService_vemas  from './images/jobCardService_vemas.PNG'
import jobCardRepair_vemas  from './images/jobCardRepair_vemas.PNG'
import jobCardNote_vemas  from './images/jobCardNote_vemas.PNG'
import jobCardImage_vemas  from './images/jobCardImage_vemas.PNG'
import jobCardPart_vemas  from './images/jobCardPart_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const JobCard = () => (
    <div className='content'>
        <h2>Job Card Management</h2>
        <div className='img-text'>
            Job card is the most important document in the mechanical workshop management system. 
            Creating the job card correctly with correct information is a vital part of the business. 
            Capturing all necessary information into the job card helps you to manage your business better.
        </div>
        <h3>Creating a job card</h3>
        <div className='img-text'>
            To create a job card, you should have already entered the customer and the vehicle into the system. If not, you must create the customer and the vehicle first.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={vehicle_vemas}/>
        </div>
        <div className='img-text'>
            Job card creation option is in Vehicle menu. 
            So, click on the “Job Card” from the top. 
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={jobCard_vemas}/>
        </div>
        <div className='img-text'>Click on “Add New” button to start the creation of a new job Card.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={jobCardHeader_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “service” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={jobCardService_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “Repair” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={jobCardRepair_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “part” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={jobCardPart_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “note” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={jobCardNote_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “image” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={jobCardImage_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “Save” button to save.
        </div>
    </div>
);

export default JobCard;