import React from 'react';
import menu_vemas from './images/menu_make_vemas.png'
import make_vemas  from './images/make_vemas.PNG'
import makeAdd_vemas  from './images/makeAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Make = () => (
    <div className='content'>
        <h2>Master Data Management</h2>
        <h3>Make</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={menu_vemas}/>
        </div>
        <div className='img-text'>Click on the gear icon and click on “Make”. This will take you to the make list screen which shows the available makes in the system.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={make_vemas}/>
        </div>
        <div className='img-text'>If you want to add a new make, just click on “Add New”</div>
        <div className='img-content'>
            <img alt="img" id="img" src={makeAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new make.</div>
        <div className='img-text'>
            You can go to edit screen just by clicking the pencil icon. Make necessary changes and press “Save” button to save.You can delete Makes just by clicking the bin icon 
        </div>
    </div>
);

export default Make;