import React from 'react';
import menu_vemas from './images/menu_model_vemas.png'
import model_vemas  from './images/model_vemas.PNG'
import modelAdd_vemas  from './images/modelAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Model = () => (
    <div className='content'>
        <h2>Master Data Management</h2>
        <h3>Models</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={menu_vemas}/>
        </div>
        <div className='img-text'>Click on the icon and then click on “Models”. This will take you to the model list screen which shows the available models in the system.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={model_vemas}/>
        </div>
        <div className='img-text'>Click on “Add New” button at top right of the screen.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={modelAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything you can enter “Save” button at bottom right of the screen.</div>
        <div className='img-text'>
            Press pencil icon to edit model. Make necessary changes and press save button.Press bin icon to delete the model.
        </div>
    </div>
);

export default Model;