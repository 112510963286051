import React from 'react';
import calendar_vemas from './images/calendar_vemas.PNG'
import bookingAdd_vemas  from './images/bookingAdd_vemas.PNG'
import dashboard_calender_vemas  from './images/dashboard_calender_vemas.png'
import "@ui5/webcomponents-icons/dist/AllIcons";


const BookingGraphical = () => (
    <div className='content'>
        <h2>Service Booking Diary</h2>
        <h3>Creating a booking</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={dashboard_calender_vemas}/>
        </div>
        <div className='img-text'>
            Click on the “Calendar” button from the left-hand side menu.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={calendar_vemas}/>
        </div>
        <div className='img-text'>
            Once “Calendar” button has been clicked, you will be redirected to the ‘Calendar’ page. 
            Calendar has three different views as month, week and day. 
            You can change the view just by clicking the one of three buttons at the top right of the page.
        </div>
        <div className='img-text'>
            To create an appointment simply click on the day that appointment begins. 
            Appointment Details pop up screen will be displayed to capture appointment details.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={bookingAdd_vemas}/>
        </div>
        <div className='img-text'>
            Once the booking is saved, it will be displayed on the date.
        </div>
        <div className='img-text'>
            click on the booking and go to booking detail screen, then make necessary changes and press “Save” button to save.
        </div>
    </div>
);

export default BookingGraphical;