import React from 'react';
import menu_vemas from './images/menu_vehicle_vemas.png'
import vehicleType_vemas  from './images/vehicleType_vemas.PNG'
import vehicleTypeAdd_vemas  from './images/vehicleTypeAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const VehicleTypes = () => (
    <div className='content'>
        <h2>Master Data Management</h2>
        <h3>Vehicle Types</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={menu_vemas}/>
        </div>
        <div className='img-text'>Click on the gear icon and click on “Vehicle Types”. This will take you to the Vehicle Types list screen which shows the available Vehicle Types in the system.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={vehicleType_vemas}/>
        </div>
        <div className='img-text'>If you want to add a new vehicle types, just click on “Add New”</div>
        <div className='img-content'>
            <img alt="img" id="img" src={vehicleTypeAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new vehicle types.</div>
        <div className='img-text'>
            You can go to edit screen just by clicking the pencil icon. Vehicle types necessary changes and press “Save” button to save.You can delete vehicle types just by clicking the bin icon 
        </div>
    </div>
);

export default VehicleTypes;