import React from 'react';
import profile_vemas from './images/profile_vemas.png'
import finance_vemas  from './images/finance_vemas.PNG'
import profile_business  from './images/profile_business.PNG'
import correspondence_vemas  from './images/correspondence_vemas.PNG'
import service_coverage_vemas  from './images/service_coverage_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Profile = () => (
    <div className='content'>
        <h2>Settings</h2>
        <h3>Profile</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={profile_vemas}/>
        </div>
        <div className='img-text'>Click on the gear icon and click on “Company Profile”.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={profile_business}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “Finance” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={finance_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “Correspondence” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={correspondence_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “Service Coverage” button move to next step.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={service_coverage_vemas}/>
        </div>
        <div className='img-text'>
            Once you have entered everything press “Save” button to save.
        </div>
    </div>
);

export default Profile;