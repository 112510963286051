import React from 'react';
import bookingList_vemas from './images/bookingList_vemas.PNG'
import bookingListAdd_vemas  from './images/bookingListAdd_vemas.PNG'
import dashboard_calender_vemas  from './images/dashboard_calender_vemas.png'
import "@ui5/webcomponents-icons/dist/AllIcons";


const BookingList = () => (
    <div className='content'>
        <h2>Service Booking Diary</h2>
        <h3>Creating a booking</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={dashboard_calender_vemas}/>
        </div>
        <div className='img-text'>
            Click on the “Calendar” button from the left-hand side menu.
        </div>
        <div className='img-text'>
            Click on the “List” button from the top.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={bookingList_vemas}/>
        </div>
        <div className='img-text'>Click on “Add New” button to start the creation of a new appointment.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={bookingListAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new appointment.</div>
        <div className='img-text'>
            To edit appointment details, just click on the button with pencil icon. 
            That will take you to the appointment detail screen. 
            To edit any information simply click on the text box you wish to make changes and make the necessary changes. 
            Press save button to save changes.To delete appointment, press the button bin icon. This will prompt a message to get your confirmation. You cannot recover once you deleted the appointment.
        </div>
    </div>
);

export default BookingList;