import React from 'react';
import menu_title_vemas from './images/menu_title_vemas.png'
import title_vemas  from './images/title_vemas.PNG'
import titleAdd_vemas  from './images/titleAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Title = () => (
    <div className='content'>
        <h2>Master Data Management</h2>
        <h3>Title</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={menu_title_vemas}/>
        </div>
        <div className='img-text'>Click on the gear icon and click on “Title”. This will take you to the title list screen which shows the available titles in the system.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={title_vemas}/>
        </div>
        <div className='img-text'>If you want to add a new title, just click on “Add New”</div>
        <div className='img-content'>
            <img alt="img" id="img" src={titleAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new title.</div>
        <div className='img-text'>
            You can go to edit screen just by clicking the pencil icon. Title necessary changes and press “Save” button to save.You can delete titles just by clicking the bin icon 
        </div>
    </div>
);

export default Title;