import React from 'react';
import sales_vemas from './images/sales_vemas.png'
import invoice_vemas  from './images/invoice_vemas.PNG'
import invoiceAdd_vemas  from './images/invoiceAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Invoice = () => (
    <div className='content'>
        <h2>Invoicing</h2>
        <div className='img-text'>
        Customer invoice is designed to provide detail information about the work you performed to customer. And it also provides other important information that you customer should know about the vehicle. 
        Invoice is a legal document and make sure all information on the invoice is true.
        </div>
        <h3>Create an invoice</h3>
        <div className='img-content'>
            <img alt="img" id="img" src={sales_vemas}/>
        </div>
        <div className='img-text'>
            Click on “Sales” button. This will take you to the customer list screen. 
            From there click on the “Invoice” button at the top of the screen. 
            List of existing invoices will be displayed. 
            To create a new invoice press “Add New” button. That will take you to the invoice detail screen. 
            There are two ways of completing the invoice.
        </div>
        {/* <div className='img-text'>Once you clicked “Sales” menu, you will be taken to a page which list existing customers.</div> */}
        <div className='img-content'>
            <img alt="img" id="img" src={invoice_vemas}/>
        </div>
        <div className='img-text'>Click on “Add New” button to start the creation of a new invoice.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={invoiceAdd_vemas}/>
        </div>
        <div className='img-text'>Once you have entered everything press “Save” button to save the new invoice.</div>
        <div className='img-text'>
        Press pencil icon from the invoice list which will take you to the invoice detail screen. You can make necessary changes to the invoice and press “Save button” to save.
        </div>
    </div>
);

export default Invoice;