import './App.css';
import Dashboard from './dashboard';
import Sidebar from './SideBar';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Make from './Make';
import Model from './Model';
import Customer from './Customer';
import Profile from './Profile';
import Vehicle from './Vehicle';
import BookingGraphical from './BookingGraphical';
import BookingList from './BookingList';
import JobCard from './JobCard';
import Invoice from './Invoice';
import VehicleTypes from './VehicleTypes';
import Part from './Part';
import Title from './Title';
import ServiceSchedule from './ServiceShedule';

function App() {
  return (
    // <div style={{display:'flex'}}>
    //     <Sidebar/>
    //     <Dashboard/>
    // </div>
    <HashRouter>
      <div style={{display:'flex'}}>
          <Sidebar/>
          <div className='page_content'>
            <Switch>
              <Route exact path='/' component={ Dashboard } />
              <Route path='/make' component={ Make } />
              <Route path='/models' component={ Model } />
              <Route path='/customer' component={ Customer } />
              <Route path='/profile' component={ Profile } />
              <Route path='/vehicle' component={ Vehicle } />
              <Route path='/bookingGraphical' component={ BookingGraphical } />
              <Route path='/bookingList' component={ BookingList } />
              <Route path='/jobCard' component={ JobCard } />
              <Route path='/invoice' component={ Invoice } />
              <Route path='/vehicleTypes' component={ VehicleTypes } />
              <Route path='/part' component={ Part } />
              <Route path='/title' component={ Title } />
              <Route path='/serviceSchedule' component={ ServiceSchedule } />
          </Switch>
          </div>
      </div>
    </HashRouter>
  );
}

export default App;
