import React from 'react';
import dashboard_vemas from './images/vemas_home_page.jpg'
import vemas from './images/VEMASLOGO.png'


const Dashboard = () => (
    <div className='content'>
        <div className='dashboard-header'>
            <img  alt="vemas" id="vemas" src={vemas} />
            <h2>Join the growing VEMAS community and run your workshop better</h2>
        </div>
        <img alt="img" id="img" src={dashboard_vemas}/>
    </div>
);

export default Dashboard;