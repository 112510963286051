import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { Link } from 'react-router-dom';
import vemas from './images/VEMASLOGO.png'
// import {AddCircleOutlineIcon} from '@mui/icons-material';



class Sidebar extends Component {
    render() {
        return (
            <div className='menuBar'>
            <img onClick={()=> window.location.href='/'}  alt="vemas" id="vemas" src={vemas} />
                <div className='mainMenu'>
                    <Collapsible trigger="+  Settings" triggerWhenOpen="-  Settings">
                        <div className='menuItem'>
                            <Link to='./profile'><p>Company Profile
                                {/* <AddCircleOutlineIcon/> */}
                                </p></Link>
                        </div>
                    </Collapsible>
                </div>
                <div className='mainMenu'>
                    <Collapsible trigger="+  Master Data" triggerWhenOpen="-  Master Data">
                        <div className='menuItem'>
                            <Link to='./make'><p>Makes</p></Link>
                            <Link to='./models'><p>Models</p></Link>
                            <Link to='./vehicleTypes'><p>Vehicle Types</p></Link>
                            <Link to='./part'><p>Part</p></Link>
                            <Link to='./title'><p>Title</p></Link>
                            <Link to='./serviceSchedule'><p>Service Schedule</p></Link>
                        </div>
                    </Collapsible>
                </div>
                <div className='mainMenu'>
                    <Collapsible trigger="+  Customer Data" triggerWhenOpen="-  Customer Data">
                        <div className='menuItem'>
                            <Link to='./customer'><p>Add a new customer</p></Link>
                        </div>
                    </Collapsible>
                </div>
                <div className='mainMenu'>
                    <Collapsible trigger="+  Vehicle Data" triggerWhenOpen="-  Vehicle Data">
                        <div className='menuItem'>
                            <Link to='./vehicle'><p>Add a new vehicle</p></Link>
                        </div>
                    </Collapsible>
                </div>
                <div className='mainMenu'>
                    <Collapsible trigger="+  Booking Diary" triggerWhenOpen="-  Booking Diary">
                        <div className='menuItem'>
                            <Link to='./bookingGraphical'><p>Graphical Calendar</p></Link>
                            <Link to='./bookingList'><p>Calendar list view</p></Link>
                        </div>
                    </Collapsible>
                </div>
                <div className='mainMenu'>
                    <Collapsible trigger="+  Job Card" triggerWhenOpen="-  Job Card">
                        <div className='menuItem'>
                            <Link to='./jobCard'><p>Creating a job card</p></Link>
                        </div>
                    </Collapsible>
                </div>
                <div className='mainMenu'>
                    <Collapsible trigger="+  Invoice" triggerWhenOpen="-  Invoice">
                        <div className='menuItem'>
                            <Link to='./invoice'><p>Creating a invoice</p></Link>
                        </div>
                    </Collapsible>
                </div>
            </div>
        )
    }
}


export default Sidebar;