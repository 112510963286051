import React from 'react';
import vehicle_vemas from './images/vehicle_vemas.png'
import vehicle_list_vemas  from './images/vehicle_list_vemas.PNG'
import vehicleAdd_vemas  from './images/vehicleAdd_vemas.PNG'
import "@ui5/webcomponents-icons/dist/AllIcons";


const Vehicle = () => (
    <div className='content'>
        <h2>Vehicle Data Management</h2>
        <div className='img-text'>
            Maintaining vehicle information is a one-off task but this information will be used throughout the system. Every attribute of the vehicle that capture will be useful to run your business better.
        </div>
        <h3>Add a new vehicle</h3>
        <div className='img-text'>
            Click on the “Vehicle” button from the left-hand side main menu.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={vehicle_vemas}/>
        </div>  
        <div className='img-text'>
            Once “Vehicle” button is clicked you will be directed to the page titled ‘Vehicle list’ which list out all existing vehicles. To create a new vehicle, click the “Add New” button at the top right of the page.
        </div>
        <div className='img-content'>
            <img alt="img" id="img" src={vehicle_list_vemas}/>
        </div>
        <div className='img-text'>Click on “Add New” button to start the creation of a new vehicle.</div>
        <div className='img-content'>
            <img alt="img" id="img" src={vehicleAdd_vemas}/>
        </div>
        <div className='img-text'>
            After clicking “Add New” the page ‘Vehicle Details” will be displayed. 
            Proceed to enter relevant information. 
            Ensure that boxes with red asterisks are filled as they are mandatory fields. 
            Each field is designed with a purpose. Values you enter here will be used somewhere in the system. 
            Make sure you enter correct information. 
            As an example, service due date will be used for identifying vehicles due for service.
            Once you entered details press “Save” button to save data.
        </div>
        <div className='img-text'>
            From vehicle list, click on the pencil button. That will take you to the vehicle details screen. Make the changes you want and press “Save” button.
        </div>
    </div>
);

export default Vehicle;